import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.scss';
import ContactItem from './ContactItem';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone.svg';
import { ReactComponent as EmailIcon } from '../assets/icons/email.svg';
import { ReactComponent as PinIcon } from '../assets/icons/pin.svg';
import { ReactComponent as BookIcon } from '../assets/icons/book.svg';

const OPEN_HOURS1 = '09:00 - 13:00';
const OPEN_HOURS2 = '14:00 - 19:00';
const OPEN_HOURS3 = '08:00 - 12:00';
const PHONE_NUMBBER = '0758574825';
const EMAIL = 'viladerident@gmail.com';
const ADDRESS_LINK = 'https://goo.gl/maps/RxbMe4jEEEdH2xAk7';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <section className="contact" id="contact">
      <h2 className="contact__title">{t('contact.title')}</h2>
      <div className="contact__wrapper">
        <div className="contact__items">
          <ContactItem
            transitionDelay="0.6"
            Icon={BookIcon}
            title={t('contact.schedule')}
          >
            <div className="contact__table">
              <div>{t('contact.monday')}</div>
              <div className="contact__delimiter" />
              <div>
                {OPEN_HOURS1}
                {' '}
                <br />
                {' '}
                {OPEN_HOURS2}
              </div>
              <div>{t('contact.friday')}</div>
              <div className="contact__delimiter" />
              <div>{OPEN_HOURS3}</div>
              <div>{t('contact.saturday')}</div>
              <div className="contact__delimiter" />
              <div>{t('contact.closedText')}</div>
              <div>{t('contact.sunday')}</div>
              <div className="contact__delimiter" />
              <div>{t('contact.closedText')}</div>
            </div>
          </ContactItem>
          <div>
            <a
              className="contact__link contact__link--phone"
              href={`tel: ${PHONE_NUMBBER}`}
              target="_blank"
              rel="noreferrer"
            >
              <ContactItem
                transitionDelay="0.4"
                Icon={PhoneIcon}
                title={t('contact.phone')}
              >
                {PHONE_NUMBBER}
              </ContactItem>
            </a>
            <a
              className="contact__link"
              href={`mailto: ${EMAIL}`}
              target="_blank"
              rel="noreferrer"
            >
              <ContactItem
                transitionDelay="0.2"
                Icon={EmailIcon}
                title={t('contact.email')}
              >
                {EMAIL}
              </ContactItem>
            </a>
          </div>
          <a
            className="contact__link contact__link--address"
            href={ADDRESS_LINK}
            target="_blank"
            rel="noreferrer"
          >
            <ContactItem transitionDelay="0" Icon={PinIcon} title={t('contact.address')}>
              <span className="contact__address-item">Electromagnetica Business Park</span>
              <span className="contact__address-item">Calea Rahovei 266-268</span>
              <span className="contact__address-item">Corp 104</span>
              <span className="contact__address-item">Parter</span>
              <span className="contact__address-item">Camera 04</span>
              <span className="contact__address-item">București 050912</span>
            </ContactItem>
          </a>
        </div>
        <span className="contact__text">{t('contact.text')}</span>
      </div>
    </section>
  );
}
