import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from './Accordion';
import './Prices.scss';

const PRICE_LIST = [
  {
    title: 'consultatie',
    items: [
      { name: 'consultatie', price: '80' },
      { name: 'radiografie', price: '50' },
      { name: 'status', price: '310' },
      { name: 'detartraj', price: '100' },
      { name: 'periaj', price: '90' },
      { name: 'airFlow', price: '110' },
      { name: 'allInclusive', price: '310' },
      { name: 'pacientNou', price: '310' },
    ],
  },
  {
    title: 'odontoterapie',
    items: [
      { name: '1suprafata', price: '220' },
      { name: '2suprafete', price: '250' },
      { name: '3suprafete', price: '290' },
      { name: '4suprafete', price: '370' },
      { name: 'obturatie', price: '200' },
    ],
  },
  {
    title: 'parodontologie',
    items: [
      { name: 'chiuretaj', price: '110' },
      { name: 'fisa', price: '160' },
      { name: 'tratament', price: '1340' },
      { name: 'indice', price: '60' },
      { name: 'control', price: '210' },
    ],
  },
  {
    title: 'estetica',
    items: [
      { name: 'fatetaCompozit', price: '830' },
      { name: 'fatetaEmax', price: '2270' },
      { name: 'albire', price: '1030' },
      { name: 'domiciliu', price: '830' },
      { name: 'bijuterie', price: '260' },
    ],
  },
  {
    title: 'protetica',
    items: [
      { name: 'coroanaMetalCeramica', price: '1550' },
      { name: 'coroanaMetal', price: '1650' },
      { name: 'coroanaCeramica', price: '2060' },
      { name: 'coroanaCeramicaImplant', price: '2170' },
      { name: 'coroanaEmax', price: '2270' },
      { name: 'proteza', price: '370' },
      { name: 'protezaTotala', price: '1860' },
    ],
  },
  {
    title: 'endodontie',
    items: [
      { name: 'monoradicular', price: '380' },
      { name: 'pluriradicular', price: '580' },
      { name: 'tratament', price: '210 - 370' },
      { name: 'obturatie', price: '170 - 330' },
      { name: 'pansament', price: '120' },
      { name: 'pivot', price: '210' },
    ],
  },
  {
    title: 'ortodontie',
    items: [
      { name: 'diagnostic', price: '2000' },
      { name: 'lite1Arcada', price: '8300' },
      { name: 'lite2Arcade', price: '14000' },
      { name: 'moderate1Arcada', price: '12000' },
      { name: 'moderate2Arcade', price: '17000' },
      { name: 'comprehensive', price: '21500' },
    ],
  },
  {
    title: 'pedodontie',
    items: [
      { name: 'periaj', price: '210' },
      { name: 'detartraj', price: '110' },
      { name: 'sigilarePerm', price: '160' },
      { name: 'obturatie', price: '160 - 240' },
      { name: 'extractie', price: '130' },
    ],
  },
  {
    title: 'chirurgie',
    items: [
      { name: 'monoradicular', price: '230' },
      { name: 'pluriradicular', price: '310' },
      { name: 'implant', price: '3090' },
      { name: 'buretel', price: '110' },
      { name: 'extractieMolar', price: '420 - 620' },
      { name: 'sinus', price: '6000' },
      { name: 'grefa', price: '1500' },
    ],
  },
];

export default function Prices() {
  const { t } = useTranslation();

  return (
    <div id="preturi" className="prices">
      <section className="prices">
        <h2 className="prices__title">{t('prices.title')}</h2>
        <div>
          {PRICE_LIST.map((e) => <Accordion key={e.title} title={e.title} items={e.items} />)}
        </div>
        <div className="prices__text">{t('prices.text')}</div>
      </section>
    </div>
  );
}
