import * as React from 'react';
import './Homepage.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import cabinet from '../assets/img/slider/cabinet.png';
import cabinetWebp from '../assets/img/slider/cabinet.webp';
import decor from '../assets/img/slider/decor.png';
import decorWebp from '../assets/img/slider/decor.webp';
import invisalign from '../assets/img/slider/invisalign.png';
import invisalignWebp from '../assets/img/slider/invisalign.webp';
import radiologie from '../assets/img/slider/radiologie.png';
import radiologieWebp from '../assets/img/slider/radiologie.webp';
import turbine from '../assets/img/slider/turbine.png';
import turbineWebp from '../assets/img/slider/turbine.webp';
import scrollToTargetAdjusted from '../utils/scrollIntoViewAdjusted';

interface SliderItemsProps {
  img: string;
  imgWebp: string;
  text: string;
  altText: string;
}
export default function Homepage() {
  const location = useLocation();
  const { t } = useTranslation();

  const sliderItems: SliderItemsProps[] = [
    {
      img: cabinet,
      imgWebp: cabinetWebp,
      text: t('homepage.slide1'),
      altText: 'Cabinet stomatologic',
    },
    {
      img: decor,
      imgWebp: decorWebp,
      text: t('homepage.slide2'),
      altText: 'Decor cabinet',
    },
    {
      img: invisalign,
      imgWebp: invisalignWebp,
      text: t('homepage.slide3'),
      altText: 'Invisalign',
    },
    {
      img: radiologie,
      imgWebp: radiologieWebp,
      text: t('homepage.slide4'),
      altText: 'Radiologie',
    },
    {
      img: turbine,
      imgWebp: turbineWebp,
      text: t('homepage.slide5'),
      altText: 'Turbine',
    },
  ];

  React.useEffect(() => {
    scrollToTargetAdjusted(location.hash.replace('#', ''));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSliderItems = ({
    img, text, altText, imgWebp,
  }: SliderItemsProps, index: number) => {
    const rightImage = index === 1 || index === 3 ? 'homepage__image--right' : '';
    return (
      <div
        key={altText}
        className="homepage__slider-page"
      >
        <picture>
          <source srcSet={imgWebp} type="image/webp" />
          <source srcSet={img} type="image/png" />
          <img className={`homepage__image ${rightImage}`} src={img} alt={altText} />
        </picture>
        <div className="homepage__overlay-text dscheduler-button">{text}</div>

      </div>
    );
  };

  return (
    <div id="acasa">
      <Slider
        dots
        infinite
        autoplay
        speed={2000}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
      >
        {sliderItems.map((renderSliderItems))}
      </Slider>
    </div>
  );
}
