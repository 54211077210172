import * as React from 'react';
import './ContactItem.scss';
import useRevealOnScroll from '../hooks/useRevealOnScroll';

interface ContactItemProps {
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  transitionDelay: string;
}

export default function ContactItem(
  {
    title,
    Icon,
    transitionDelay,
    children,
  }: React.PropsWithChildren<ContactItemProps>,
) {
  const { elementRef, visible } = useRevealOnScroll();

  return (
    <div
      style={{ transitionDelay: `${transitionDelay}s` }}
      className={`contact-item ${visible ? 'contact-item--visible' : ''}`}
      ref={elementRef}
    >
      <Icon className="contact-item__icon" />
      <div className="contact-item__title">{title}</div>
      <div className="contact-item__content">{children}</div>
    </div>
  );
}
