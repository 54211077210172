import './Services.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ServiceCard from './ServiceCard';

const ITEMS = [
  'estetica',
  'implantologie',
  'profilaxie',
  'urgente',
  'protetica',
  'parodontoza',
  'ortodontie',
  'endodontie',
  'pedodontie',
  'disfunctii',
  'tratament',
  'frica',
  'turism',
];

export default function Services() {
  const { t } = useTranslation();

  return (
    <section id="servicii" className="services">
      <h2 className="services__title">{t('services.title')}</h2>
      <div className="services__list">
        {ITEMS.map((item) => (
          <ServiceCard
            key={item}
            description={t(`services.${item}.description`)}
            title={t(`services.${item}.title`)}
          />
        ))}
      </div>
    </section>
  );
}
