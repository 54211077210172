import * as React from 'react';
import logo from '../assets/img/viladeri-logo.png';
import useRevealOnScroll from '../hooks/useRevealOnScroll';
import './ServiceCard.scss';

interface ServiceCardProps {
  title: string;
  description: string;
}

export default function ServiceCard({ title, description }: ServiceCardProps) {
  const [zIndex, setZIndex] = React.useState<number | undefined>(undefined);
  const [style, setStyle] = React.useState<{transform?: string}>({});
  const [hovered, setHovered] = React.useState(false);

  const { elementRef, visible } = useRevealOnScroll();

  const magnify = () => {
    setZIndex(2);
    setHovered(true);

    const rect = elementRef.current?.getBoundingClientRect();

    if (rect) {
      if (rect?.left < 74) {
        setStyle({ transform: 'scale(2) translate(3.7rem)' });
      } else if ((window.innerWidth - rect.right) < 74) {
        setStyle({ transform: 'scale(2) translate(-3.7rem)' });
      } else {
        setStyle({ transform: 'scale(2)' });
      }
    }
  };

  const minify = () => {
    setStyle({});
    setHovered(false);
  };

  const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const onHoverEnd = () => {
    minify();
  };

  const onHoverStart = () => {
    if (!isMobile()) {
      magnify();
    }
  };

  const onTransitionEnd = (e: React.TransitionEvent<HTMLDivElement>) => {
    // @ts-ignore
    if (!e.target.style.transform) {
      setZIndex(undefined);
    }
  };

  const onClick = () => {
    if (hovered === true) {
      minify();
    } else {
      magnify();
    }
  };

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      style={{ zIndex }}
      ref={elementRef}
      className={`service-card ${visible ? 'service-card--visible' : ''} ${hovered ? 'service-card--hovered' : ''}`}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      onTransitionEnd={onTransitionEnd}
      onClick={onClick}
    >
      <div
        style={{ ...style }}
        className="service-card__wrapper"
      >
        <div className="service-card__main" onTransitionEnd={(e) => e.stopPropagation()}>
          <img src={logo} className="service-card__logo" alt="logo" />
          <div className="service-card__title">{title}</div>
        </div>
        <div className="service-card__description-wrapper" onTransitionEnd={(e) => e.stopPropagation()}>
          <div className="service-card__description">{description}</div>
        </div>
      </div>
    </div>
  );
}
