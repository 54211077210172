/* eslint-disable react/no-danger */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './ConfidentialityPolicy.scss';

export default function ConfidentialityPolicy() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.className = 'body-no-padding';

    return () => {
      document.body.className = '';
    };
  }, []);

  return (
    <div className="confidentiality">
      <h2 className="confidentiality__big-title">{t('confidentiality.bigTitle')}</h2>
      <p className="confidentiality__description">{t('confidentiality.text1')}</p>
      <p className="confidentiality__description">{t('confidentiality.text2')}</p>
      <p className="confidentiality__description">{t('confidentiality.text3')}</p>
      <p className="confidentiality__description">{t('confidentiality.text4')}</p>
      <h5 className="confidentiality__subtitle confidentiality__subtitle--margin-top">
        {t('confidentiality.firstChapter.title')}
      </h5>
      <ol className="confidentiality__ol-list">
        <li className="confidentiality__description">{t('confidentiality.firstChapter.item1')}</li>
        <p className="confidentiality__description">{t('confidentiality.firstChapter.text1')}</p>
        <li className="confidentiality__description">{t('confidentiality.firstChapter.item2')}</li>
        <p className="confidentiality__description">{t('confidentiality.firstChapter.text2')}</p>
        <p className="confidentiality__description">{t('confidentiality.firstChapter.text3')}</p>
        <p className="confidentiality__description">{t('confidentiality.firstChapter.text4')}</p>
        <h5 className="confidentiality__subtitle confidentiality__subtitle--margin-top">
          {t('confidentiality.secondChapter.title')}
        </h5>
        <li className="confidentiality__description">{t('confidentiality.secondChapter.item3')}</li>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text1')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text2')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text3')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text4')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text5')}</p>
        <li className="confidentiality__description">{t('confidentiality.secondChapter.item4')}</li>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text6')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text7')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text8')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text9')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text10')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text11')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text12')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text13')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text14')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text15')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text16')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text17')}</p>
        <li className="confidentiality__description">{t('confidentiality.secondChapter.item5')}</li>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text18')}</p>
        <li className="confidentiality__description">{t('confidentiality.secondChapter.item6')}</li>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text19')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text20')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text21')}</p>
        <ul className="confidentiality__ul-list">
          <li className="confidentiality__description">{t('confidentiality.secondChapter.subItem1')}</li>
          <li className="confidentiality__description">{t('confidentiality.secondChapter.subItem2')}</li>
        </ul>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text22')}</p>
        <li className="confidentiality__description">{t('confidentiality.secondChapter.item7')}</li>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text23')}</p>
        <p className="confidentiality__description">{t('confidentiality.secondChapter.text24')}</p>
        <h5 className="confidentiality__subtitle confidentiality__subtitle--margin-top">
          {t('confidentiality.thirdChapter.title')}
        </h5>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item8')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text1')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item9')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text2')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item10')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text3')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item11')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text4')}</p>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text5')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item12')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text6')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item13')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text7')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item14')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text8')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item15')}</li>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text9')}</p>
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text10')}</p>
        <li className="confidentiality__description">{t('confidentiality.thirdChapter.item16')}</li>
        <p
          className="confidentiality__description"
          dangerouslySetInnerHTML={{ __html: t('confidentiality.thirdChapter.text11') }}
        />
        <p className="confidentiality__description">{t('confidentiality.thirdChapter.text12')}</p>
        <h5 className="confidentiality__subtitle confidentiality__subtitle--margin-top">
          {t('confidentiality.fourthChapter.title')}
        </h5>
        <li className="confidentiality__description">{t('confidentiality.fourthChapter.item17')}</li>
        <li className="confidentiality__description">{t('confidentiality.fourthChapter.item18')}</li>
        <li className="confidentiality__description">{t('confidentiality.fourthChapter.item19')}</li>
      </ol>
    </div>
  );
}
