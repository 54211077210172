import * as React from 'react';
import './ScheduleButton.scss';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from './Footer';

export default function ScheduleButton() {
  const { t } = useTranslation();

  return (
    <div className="schedule dscheduler-button">
      {capitalizeFirstLetter(t('mainLinks.schedule'))}
    </div>
  );
}
