import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useRevealOnScroll from '../hooks/useRevealOnScroll';
import './Motto.scss';
import boySmile from '../assets/img/motto/boy-smile.png';
import boySmileWebp from '../assets/img/motto/boy-smile.webp';
import childSmile from '../assets/img/motto/child-smile.png';
import childSmileWebp from '../assets/img/motto/child-smile.webp';
import girlSmile from '../assets/img/motto/girl-smile.png';
import girlSmileWebp from '../assets/img/motto/girl-smile.webp';
import manSmile from '../assets/img/motto/man-smile.png';
import manSmileWebp from '../assets/img/motto/man-smile.webp';
import oldWomanSmile from '../assets/img/motto/old-woman-smile.png';
import oldWomanSmileWebp from '../assets/img/motto/old-woman-smile.webp';
import womanSmile from '../assets/img/motto/woman-smile.png';
import womanSmileWebp from '../assets/img/motto/woman-smile.webp';

interface MottoText {
  text: string;
  right: boolean
}

export default function Motto() {
  const { t } = useTranslation();
  const { elementRef, visible } = useRevealOnScroll();

  const mottoPhotos = [
    { img: boySmile, webpImg: boySmileWebp },
    { img: womanSmile, webpImg: womanSmileWebp },
    { img: oldWomanSmile, webpImg: oldWomanSmileWebp },
    { img: childSmile, webpImg: childSmileWebp },
    { img: manSmile, webpImg: manSmileWebp },
    { img: girlSmile, webpImg: girlSmileWebp }];

  const mottoText: MottoText[] = [
    {
      text: t('motto.text1'),
      right: false,
    },
    {
      text: t('motto.text2'),
      right: true,
    },
    {
      text: t('motto.text3'),
      right: false,
    },
  ];

  const mottoParagraph = ({ text, right }: MottoText) => {
    const paragraphPosition = `motto__paragraph--${right ? 'right' : 'left'}`;
    const paragraphVisibility = `${visible ? 'motto__paragraph--visible' : ''}`;

    return (
      <p
        key={text}
        ref={elementRef}
        className={`motto__paragraph ${paragraphPosition} ${paragraphVisibility}`}
      >
        {text}
      </p>
    );
  };

  return (
    <section className="motto">
      <div className="motto__background">
        {mottoPhotos.map((photo, index) => (
          <picture key={photo.img} className={`${index === 3 ? 'motto__picture--hide' : ''}`}>
            <source srcSet={photo.webpImg} type="image/webp" />
            <source srcSet={photo.img} type="image/png" />
            <img className="motto__img" src={photo.img} alt={`Smile-${index}`} />
          </picture>
          // <img
          //   key={photo.img}
          //   className={`motto__img ${index === 3 && 'motto__img--hide'}`}
          //   src={photo.img}
          //   alt={`Smile-${index}`}
          // />
        ))}
      </div>
      <div className="motto__main-wrapper">
        <div className="motto__wrapper">
          <h2
            className={`motto__header ${visible ? 'motto__header--visible' : ''}`}
            ref={elementRef}
          >
            {t('motto.title')}
          </h2>
          <div className="motto__paragraphs">
            {mottoText.map(mottoParagraph)}
          </div>
        </div>
      </div>
    </section>
  );
}
