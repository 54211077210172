import React, { useEffect } from 'react';
import {
  Route, BrowserRouter as Router, Routes,
} from 'react-router-dom';
import './App.scss';
import Header from './components/Header';
import Homepage from './components/Homepage';
import Motto from './components/Motto';
import Prices from './components/Prices';
import Services from './components/Services';
import TermsAndConditions from './components/TermsAndConditions';
import GDPRPolicy from './components/GDPRPolicy';
import ConfidentialityPolicy from './components/ConfidentialityPolicy';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import ScheduleButton from './components/ScheduleButton';

function App() {
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line
    $.getScript(`https://secure.mydroot.eu/api/onlinescheduler/drscheduler.js?rnd=${Math.ceil(Math.random() * 1000000000)}`, () => {
      // @ts-ignore
      // eslint-disable-next-line
      $('.dscheduler-button').PGEYpBmXLo({
        apikey: 'cd8b732e18dc4e05433af523afdae83ac802b5b89421197150a56ba7c0cc9b41a0348fe2d26f', // Communication API key
        labelColor: '#444444', // html color for labels
        backgroundColor: '#ffffff', // html color for background
        textColor: '#185abc', // html color for text
        textBackgroundColor: '#ffffff', // html color for text background
        buttonColor: '#444444', // html color for button text
        buttonBackgroundColor: '#efefef', // html color for button background
        lang: 1, // Interface language 0-English, 1-Romanian, 2-Hungarian
        selectMedic: '', // Name (alias) of the selected medic
        selectWorkstation: '', // Name (alias) of the selected workstation
      });
    });
  }, []);
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route
            path="/"
            element={(
              <>
                <Header />
                <div className="app__wrapper">
                  <ScheduleButton />
                  <Homepage />
                  <Motto />
                  <main className="app__main">
                    <Services />
                    <Prices />
                    <AboutUs />
                    <Contact />
                  </main>
                </div>
                <Footer />
              </>
              )}
          />
          <Route
            path="termeni-si-conditii"
            element={<TermsAndConditions />}
          />
          <Route
            path="gdpr"
            element={<GDPRPolicy />}
          />
          <Route
            path="politica-de-confidentialitate"
            element={<ConfidentialityPolicy />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
