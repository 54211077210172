import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.scss';
import carmenVilader1 from '../assets/img/about-us/carmen-viladeri1.png';
import carmenVilader1Webp from '../assets/img/about-us/carmen-viladeri1.webp';
import carmenVilader2 from '../assets/img/about-us/carmen-viladeri2.png';
import carmenVilader2Webp from '../assets/img/about-us/carmen-viladeri2.webp';
import spyridonViladeris from '../assets/img/about-us/spyridon-viladeris.png';
import spyridonViladerisWebp from '../assets/img/about-us/spyridon-viladeris.webp';
import AboutUsSection from './AboutUsSection';

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <section
      id="despre"
      className="about-us"
    >
      <h2 className="about-us__title">{t('about.title')}</h2>
      <div className="about-us__name">Carmen-Elena Rogoz Viladeri</div>
      <AboutUsSection
        paragraphs={[
          t('about.description.carmen.parf1'),
          t('about.description.carmen.parf2'),
          t('about.description.carmen.parf3'),
        ]}
        img={carmenVilader1}
        webpImg={carmenVilader1Webp}
      />
      <AboutUsSection
        paragraphs={[
          t('about.description.carmen.parf4'),
          t('about.description.carmen.parf5'),
          t('about.description.carmen.parf6'),
          t('about.description.carmen.parf7'),
        ]}
        img={carmenVilader2}
        webpImg={carmenVilader2Webp}
        reversed
      />
      <div className="about-us__name">Spyridon Viladeris</div>
      <AboutUsSection
        paragraphs={[
          t('about.description.spyro.parf1'),
          t('about.description.spyro.parf2'),
        ]}
        img={spyridonViladeris}
        webpImg={spyridonViladerisWebp}
      />
    </section>
  );
}
