import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguagePicker.scss';

export default function LanguagePicker() {
  const { i18n } = useTranslation();

  const languageSelector = [
    { text: 'RO', iso2: 'ro' },
    { text: 'EN', iso2: 'en' },
    { text: 'GR', iso2: 'gr' },
    { text: 'DE', iso2: 'de' },
  ];

  return (
    <div className="language-picker">
      {languageSelector.map((lang) => (
        <div
          role="presentation"
          key={lang.text}
          onClick={(e) => {
            e.stopPropagation();
            i18n.changeLanguage(lang.iso2);
          }}
          className={`language-picker__language ${i18n.language === lang.iso2 ? 'language-picker__language--active' : ''}`}
        >
          <span>{lang.text}</span>
        </div>
      ))}
    </div>
  );
}
