/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import './Header.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/viladeri-logo.png';
import scrollToTargetAdjusted from '../utils/scrollIntoViewAdjusted';
import LanguagePicker from './LanguagePicker';

const MENU_ITEMS = ['acasa', 'servicii', 'preturi', 'despre', 'contact'];
const WEBSITE_NAME = 'VILADERI DENT';

export default function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = React.useState(false);

  const goToItem = (e: React.MouseEvent, item: string) => {
    e.preventDefault();
    scrollToTargetAdjusted(item);
    navigate({ hash: `${item}` });
  };

  return (
    <header className="header">
      <img
        src={logo}
        alt="logo"
        className="header__logo"
        onClick={(e) => goToItem(e, MENU_ITEMS[0])}
      />
      <div
        className="header__viladeri"
        onClick={(e) => goToItem(e, MENU_ITEMS[0])}
      >
        {WEBSITE_NAME}
      </div>
      <div className="header__right">
        <LanguagePicker />
        <div className="header__right-bottom">
          {MENU_ITEMS.map((item) => (
            <a
              key={item}
              href={`#${item}`}
              className="header__link"
              onClick={(e) => goToItem(e, item)}
            >
              {t(`mainLinks.${item}`)}
            </a>
          ))}
          <button className="header__link dscheduler-button">
            {t('mainLinks.schedule')}
          </button>
        </div>
      </div>
      <button
        className="header__burger-button"
        type="button"
        onClick={() => setShowMenu(!showMenu)}
      >
        <div
          className={`header__menu-background ${showMenu ? 'header__menu-background--active' : ''}`}
        />
        <div className={`header__menu ${showMenu ? 'header__menu--active' : ''}`}>
          {MENU_ITEMS.map((item) => (
            <a
              key={item}
              href={`#${item}`}
              className="header__menu-link"
              onClick={(e) => goToItem(e, item)}
            >
              {t(`mainLinks.${item}`)}
            </a>
          ))}
          <div className="header__menu-link dscheduler-button">
            {t('mainLinks.schedule')}
          </div>
          <LanguagePicker />
        </div>
        <div className={`header__burger ${showMenu ? 'header__burger--active' : ''}`} />
      </button>
    </header>
  );
}
