import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/img/viladeri-logo.png';
import { ReactComponent as TikTokIcon } from '../assets/icons/tiktok.svg';
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import ANPC from '../assets/img/anpc.png';
import LITIGII from '../assets/img/litigii.png';
import scrollToTargetAdjusted from '../utils/scrollIntoViewAdjusted';

const WEBSITE_NAME = 'VILADERI DENT';
const COPYRIGHT = 'Copyright © Viladeri Dent 2023';
const LITIGII_LINK = 'https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage';
const ANPC_LINK = 'https://anpc.ro/ce-este-sal/';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const SOCIAL_MEDIA = [
  {
    MyIcon: TikTokIcon,
    url: 'https://www.tiktok.com/@viladerident',
    ariaLabel: 'tiktok',
  },
  {
    MyIcon: FacebookIcon,
    url: 'https://www.facebook.com/ViladeriDent',
    ariaLabel: 'facebook',
  },
  {
    MyIcon: InstagramIcon,
    url: 'https://www.instagram.com/viladerident',
    ariaLabel: 'instagram',
  },
];

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuItems = ['acasa', 'servicii', 'preturi', 'despre', 'contact'];
  const sideItems = [
    {
      item: 'privacyPolicy',
      href: '/politica-de-confidentialitate',
    },
    {
      item: 'tos',
      href: '/termeni-si-conditii',
    },
    {
      item: 'gdpr',
      href: '/gdpr',
    },
  ];

  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__top">
          <div className="footer__top-left">
            <div className="footer__logo-and-name">
              <img src={logo} className="footer__logo" alt="logo" />
              <div className="footer__viladeri">
                {WEBSITE_NAME}
              </div>
            </div>
            <div className="footer__motto">{t('motto.title')}</div>
            <div className="footer__social-media">
              {SOCIAL_MEDIA.map(({ MyIcon, url, ariaLabel }) => (
                <a
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className="footer__social-media-link"
                  aria-label={ariaLabel}
                >
                  <MyIcon className="footer__social-media-icon" />
                </a>
              ))}
            </div>
          </div>
          <div className="footer__main-links">
            {menuItems.map((item) => (
              <a
                key={item}
                href={`#${item}`}
                className="footer__link"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToTargetAdjusted(item);
                  navigate({ hash: `${item}` });
                }}
              >
                {capitalizeFirstLetter(t(`mainLinks.${item}`))}
              </a>
            ))}
            <div className="footer__link dscheduler-button">
              {capitalizeFirstLetter(t('mainLinks.schedule'))}
            </div>
          </div>
          <div className="footer__side-links">
            {sideItems.map(({ item, href }) => (
              <a
                key={item}
                href={href}
                target="_blank"
                className="footer__link"
                rel="noreferrer"
              >
                {capitalizeFirstLetter(t(`sideLinks.${item}`))}
              </a>
            ))}
            <a href={ANPC_LINK} target="_blank" rel="noreferrer" aria-label="anpc">
              <img src={ANPC} alt="anpc" className="footer__img footer__img--anpc" />
            </a>
            <a href={LITIGII_LINK} target="_blank" rel="noreferrer" aria-label="litigii">
              <img src={LITIGII} alt="litigii" className="footer__img" />
            </a>
          </div>
        </div>
        <div className="footer__copyright">{COPYRIGHT}</div>
      </div>
    </footer>
  );
}
