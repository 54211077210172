/* eslint-disable react/no-danger */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './TermsAndConditions.scss';

export default function TermsAndConditions() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.className = 'body-no-padding';

    return () => {
      document.body.className = '';
    };
  }, []);

  return (
    <div className="terms-and-conditions">
      <h2 className="terms-and-conditions__title">{t('termsAndCond.firstChapter.title')}</h2>
      <h5 className="terms-and-conditions__subtitle">{t('termsAndCond.firstChapter.subtitle1')}</h5>
      <ol className="terms-and-conditions__ol-list">
        <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.item1')}</li>
        <ul className="terms-and-conditions__ul-list">
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet1')}</li>
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet2')}</li>
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet3')}</li>
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet4')}</li>
          <li
            className="terms-and-conditions__description"
            dangerouslySetInnerHTML={{ __html: t('termsAndCond.firstChapter.bullet5') }}
          />
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet6')}</li>
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet7')}</li>
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet8')}</li>
          <li
            className="terms-and-conditions__description"
            dangerouslySetInnerHTML={{ __html: t('termsAndCond.firstChapter.bullet9') }}
          />
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet10')}</li>
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet11')}</li>
          <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.bullet12')}</li>
          <li
            className="terms-and-conditions__description"
            dangerouslySetInnerHTML={{ __html: t('termsAndCond.firstChapter.bullet13') }}
          />
          <li
            className="terms-and-conditions__description"
            dangerouslySetInnerHTML={{ __html: t('termsAndCond.firstChapter.bullet14') }}
          />
        </ul>
        <h5 className="terms-and-conditions__subtitle terms-and-conditions__subtitle--margin-top">
          {t('termsAndCond.firstChapter.subtitle2')}
        </h5>
        <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.item2')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.item3')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.item4')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.item5')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.firstChapter.item6')}</li>
        <h2 className="terms-and-conditions__title">{t('termsAndCond.secondChapter.title')}</h2>
        <h5 className="terms-and-conditions__subtitle">{t('termsAndCond.secondChapter.subtitle1')}</h5>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item8')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item9')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item10')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item11')}</li>
        <h5 className="terms-and-conditions__subtitle terms-and-conditions__subtitle--margin-top">
          {t('termsAndCond.secondChapter.subtitle2')}
        </h5>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item12')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item13')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item14')}</li>
        <h5 className="terms-and-conditions__subtitle terms-and-conditions__subtitle--margin-top">
          {t('termsAndCond.secondChapter.subtitle3')}
        </h5>
        <li className="terms-and-conditions__description">{t('termsAndCond.secondChapter.item15')}</li>
        <h2 className="terms-and-conditions__title">{t('termsAndCond.fifthChapter.title')}</h2>
        <li className="terms-and-conditions__description">{t('termsAndCond.fifthChapter.item16')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.fifthChapter.item17')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.fifthChapter.item18')}</li>
        <h2 className="terms-and-conditions__title">{t('termsAndCond.seventhChapter.title')}</h2>
        <li className="terms-and-conditions__description">{t('termsAndCond.seventhChapter.item19')}</li>
        <h2 className="terms-and-conditions__title">{t('termsAndCond.eigthChapter.title')}</h2>
        <h5 className="terms-and-conditions__subtitle">{t('termsAndCond.eigthChapter.subtitle1')}</h5>
        <li className="terms-and-conditions__description">{t('termsAndCond.eigthChapter.item20')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.eigthChapter.item21')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.eigthChapter.item22')}</li>
        <h2 className="terms-and-conditions__title">{t('termsAndCond.ninethChapter.title')}</h2>
        <h5 className="terms-and-conditions__subtitle">{t('termsAndCond.ninethChapter.subtitle1')}</h5>
        <li className="terms-and-conditions__description">{t('termsAndCond.ninethChapter.item23')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.ninethChapter.item24')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.ninethChapter.item25')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.ninethChapter.item26')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.ninethChapter.item27')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.ninethChapter.item28')}</li>
        <h2 className="terms-and-conditions__title">{t('termsAndCond.tenthChapter.title')}</h2>
        <li className="terms-and-conditions__description">{t('termsAndCond.tenthChapter.item29')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.tenthChapter.item30')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.tenthChapter.item31')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.tenthChapter.item32')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.tenthChapter.item33')}</li>
        <li className="terms-and-conditions__description">{t('termsAndCond.tenthChapter.item34')}</li>
      </ol>
    </div>
  );
}
