const scrollToTargetAdjusted = (id: string) => {
  const element = document.getElementById(id);
  const headerOffset = document.querySelector('header')?.offsetHeight;
  const elementPosition = element?.getBoundingClientRect().top;
  const offsetPosition = (elementPosition || 0) + window.pageYOffset - (headerOffset || 0);
  window.scrollTo({
    top: offsetPosition - 20,
    behavior: 'smooth',
  });
};

export default scrollToTargetAdjusted;
