/* eslint-disable react/no-danger */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './GDPRPolicy.scss';

export default function GDPRPolicy() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.className = 'body-no-padding';

    return () => {
      document.body.className = '';
    };
  }, []);

  return (
    <div className="gdpr">
      <h2 className="gdpr__big-title">{t('gdpr.bigTitle')}</h2>
      <p
        className="gdpr__description"
        dangerouslySetInnerHTML={{ __html: t('gdpr.introText1') }}
      />
      <p className="gdpr__description">{t('gdpr.introText2')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top gdpr__subtitle--margin-bottom">
        {t('gdpr.summary.title')}
      </h5>
      <ul className="gdpr__ul-list">
        <li className="gdpr__description">{t('gdpr.summary.item1')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item2')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item3')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item4')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item5')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item6')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item7')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item8')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item9')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item10')}</li>
        <li className="gdpr__description">{t('gdpr.summary.item11')}</li>
      </ul>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.firstChapter.title')}
      </h5>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.firstChapter.article1.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.firstChapter.article1.introText1')}</p>
      <p className="gdpr__description">{t('gdpr.firstChapter.article1.introText2')}</p>
      <ul className="gdpr__ul-list">
        <li className="gdpr__description">{t('gdpr.firstChapter.article1.item1')}</li>
        <li className="gdpr__description">{t('gdpr.firstChapter.article1.item2')}</li>
        <li className="gdpr__description">{t('gdpr.firstChapter.article1.item3')}</li>
        <li className="gdpr__description">{t('gdpr.firstChapter.article1.item4')}</li>
      </ul>
      <p className="gdpr__description">{t('gdpr.firstChapter.article1.text1')}</p>
      <p className="gdpr__description">{t('gdpr.firstChapter.article1.text2')}</p>
      <p className="gdpr__description">{t('gdpr.firstChapter.article1.text3')}</p>
      <p className="gdpr__description">{t('gdpr.firstChapter.article1.text4')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.firstChapter.article2.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.firstChapter.article2.text1')}</p>
      <p className="gdpr__description">{t('gdpr.firstChapter.article2.text2')}</p>
      <p className="gdpr__description">{t('gdpr.firstChapter.article2.text3')}</p>
      <p className="gdpr__description">{t('gdpr.firstChapter.article2.text4')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.secondChapter.title')}
      </h5>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.secondChapter.article1.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.secondChapter.article1.text')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.secondChapter.article2.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.secondChapter.article2.text1')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article2.text2')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article2.text3')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article2.text4')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.secondChapter.article3.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.secondChapter.article3.text1')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article3.text2')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article3.text3')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article3.text4')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article3.text5')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.secondChapter.article4.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.secondChapter.article4.text1')}</p>
      <ul className="gdpr__ul-list">
        <li className="gdpr__description">{t('gdpr.secondChapter.article4.item1')}</li>
        <li className="gdpr__description">{t('gdpr.secondChapter.article4.item2')}</li>
      </ul>
      <p className="gdpr__description">{t('gdpr.secondChapter.article4.text2')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article4.text3')}</p>
      <ul className="gdpr__ul-list">
        <li className="gdpr__description">{t('gdpr.secondChapter.article4.item3')}</li>
        <li className="gdpr__description">{t('gdpr.secondChapter.article4.item4')}</li>
      </ul>
      <p className="gdpr__description">{t('gdpr.secondChapter.article3.text4')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.secondChapter.article5.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.secondChapter.article5.text1')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article5.text2')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article5.text3')}</p>
      <p className="gdpr__description">{t('gdpr.secondChapter.article5.text4')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.thirdChapter.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.thirdChapter.text1')}</p>
      <p className="gdpr__description">{t('gdpr.thirdChapter.text2')}</p>
      <p className="gdpr__description">{t('gdpr.thirdChapter.text3')}</p>
      <p className="gdpr__description">{t('gdpr.thirdChapter.text4')}</p>
      <h5 className="gdpr__subtitle gdpr__subtitle--margin-top">
        {t('gdpr.fourthChapter.title')}
      </h5>
      <p className="gdpr__description">{t('gdpr.fourthChapter.text1')}</p>
      <p className="gdpr__description">{t('gdpr.fourthChapter.text2')}</p>
      <p className="gdpr__description">{t('gdpr.fourthChapter.text3')}</p>
    </div>

  );
}
