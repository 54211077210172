import * as React from 'react';
import './Accordion.scss';
import AnimateHeight, { Height } from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';

interface ItemProps {
  name: string;
  price: string;
}

interface AccordionProps {
  title: string;
  items: ItemProps[]
}

export default function Accordion({ title, items }: AccordionProps) {
  const accordionRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);

  const [visible, setVisible] = React.useState(false);
  const [height, setHeight] = React.useState<Height>(0);

  const { t } = useTranslation();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
        //  else {
        //   setHeight(0);
        // }
      });
    });

    if (accordionRef.current) {
      observer.observe(accordionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function scrollIntoView() {
    listRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  return (
    <div
      className={`accordion ${visible ? 'accordion--visible' : ''}`}
      ref={accordionRef}
    >
      <button
        className="accordion__title"
        type="button"
        onClick={() => setHeight(height === 0 ? 'auto' : 0)}
        aria-controls={title}
      >
        <span>{t(`prices.listTitle.${title}`)}</span>
        <ArrowIcon
          className={`accordion__arrow ${height !== 0 ? 'accordion__arrow--rotated' : ''}`}
        />
      </button>
      <AnimateHeight
        id={title}
        duration={500}
        height={height}
        // eslint-disable-next-line react/jsx-no-bind
        onHeightAnimationEnd={scrollIntoView}
      >
        <div ref={listRef}>
          {title === 'odontoterapie' && <div className="accordion__list-item">{t(`prices.listItem.${title}.text`)}</div>}
          {items.map((item) => (
            <div className="accordion__list-item" key={item.name}>
              <div className="accordion__list-name">
                {t(`prices.listItem.${title}.${item.name}`)}
              </div>
              <div className="accordion__list-price">
                {`${item.price} RON`}
              </div>
            </div>
          ))}
        </div>
      </AnimateHeight>
    </div>
  );
}
