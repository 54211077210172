import * as React from 'react';
import useRevealOnScroll from '../hooks/useRevealOnScroll';
import './AboutUsSection.scss';

interface AboutUsSectionProps {
  paragraphs: string[];
  img: string;
  webpImg: string;
  reversed?: boolean;
}

export default function AboutUsSection({
  paragraphs, img, reversed, webpImg,
}: AboutUsSectionProps) {
  const { elementRef, visible } = useRevealOnScroll();

  const reverseSection = reversed ? 'about-us-section--reversed' : '';
  const reverseImage = reversed ? 'about-us-section__image--reversed' : '';
  const reverseParagraph = reversed ? 'about-us-section__paragraphs--reversed' : '';
  const visibleImage = visible ? 'about-us-section__image--visible' : '';
  const visibleParagraph = visible ? 'about-us-section__paragraphs--visible' : '';

  return (
    <div
      ref={elementRef}
      className={`about-us-section ${reverseSection}`}
    >
      <picture>
        <source srcSet={webpImg} type="image/webp" />
        <source srcSet={img} type="image/png" />
        <img className={`about-us-section__image ${reverseImage} ${visibleImage}`} src={img} alt="About us section" />
      </picture>
      <div
        className={`about-us-section__paragraphs ${reverseParagraph} ${visibleParagraph}`}
      >
        {paragraphs.map((item) => (
          <p key={item} className="about-us-section__paragraph">{item}</p>
        ))}
      </div>
    </div>
  );
}

AboutUsSection.defaultProps = {
  reversed: false,
};
